<template>
  <div class="m-0 p-0 distribution-input" :class="tipagem.split('.')">
    <span :id="getIdComponentTooltip">
      <input
        type="number"
        placeholder="---"
        v-model="model"
        :class="className"
        :style="getStyles"
        :disabled="desabilitarInput"
        :state="errorEmpresa"
      />
    </span>
    <input
      type="text"
      v-money="moneyMask"
      :value="getLegend"
      class="legend"
      disabled
    />
    <!-- notificação input desabilitado -->
    <span v-if="desabilitarInput">
      <b-tooltip :target="getIdComponentTooltip">{{
        getMensagemInputDesabilitado
      }}</b-tooltip>
    </span>
  </div>
</template>

<script>
import { BTooltip } from "bootstrap-vue";
import { AdesaoGetDoseVacinaEmpresa, AdesaoGetInformacoesPersistencia } from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";

export default {
  components: {
    BTooltip,
  },
  data() {
    return {
      qtdLocal: 0,
      error:false,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
    };
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    tipo: {
      type: Object,
      required: true,
    },
    qtdColaboradores: {
      type: Number,
      required: true,
    },
    textColor: {
      type: String,
      default: "#5E5873",
    },
    backgroundColor: {
      type: String,
      default: "#F3EEFF",
    },
    borderColor: {
      type: String,
      default: "#8C65E2",
    },
    width: {
      type: Number,
      default: 57,
    },
    height: {
      type: Number,
      default: 27,
    },
    tipagem: {
      type: String,
      required: true,
    },
    vacina: {
      type: Object,
      required: true,
    },
    empresa: {
      type: Object,
      required: true,
    },
    ModoCriacao: {
      type: Boolean,
      required: true,
    },
    ModoVisualizacao: {
      type: Boolean,
      required: true,
    },
    semValidacaoColaborador: {
      type: Boolean
    },
  },

  beforeMount() {
    this.qtdLocal = this.tipo.qtd;
  },

  created() {
    let qtdPedido = AdesaoGetDoseVacinaEmpresa({
      tipagem: this.tipagem,
      vacina: this.vacina,
      empresa: this.empresa,
    });

    if (!this.ModoCriacao && qtdPedido > 0) {
      this.qtdLocal = qtdPedido;
    }
  },

  computed: {
    errorEmpresa() {
      let erro = false;
      const adesao = AdesaoGetInformacoesPersistencia().mensagens;
      if(this.empresa){
         erro = adesao.id_empresa == this.empresa.id_empresa
      }
      if(this.empresa && this.tipo && adesao.id_categoria){
        erro = adesao.id_categoria == this.tipo.id_categoria &&
        adesao.id_empresa == this.empresa.id_empresa
      }

      if(adesao.id_incentivo && this.tipo){
        erro = adesao.id_incentivo == this.tipo.id_incentivo;
      }

      if(erro){
          this.error = erro;
        }
      return erro;
    },
    model: {
      get() {
        return this.getValue;
      },
      set(value) {
        value = this.$helpers.somenteNumeros(value);
        this.qtdLocal = Number(value);
        this.error = false;
        this.$emit("input", this.qtdLocal);
      },
    },

    getValue() {
      return this.qtdLocal;
    },

    getLegend() {
      return (this.tipo.valor * this.qtdLocal).toFixed(2);
    },

    getStyles() {
      let borderColor = this.desabilitarInput
          ? "#d8d6de"
          : this.backgroundColor,
        backgroundColor = this.desabilitarInput
          ? "#efefef"
          : this.backgroundColor;

        if(this.error && this.qtdLocal > 0){
          borderColor = '#EA5455';
          backgroundColor = '#EA54551F';
        }

      return {
        color: this.textColor,
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`,
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },

    desabilitarInput() {
      if(this.semValidacaoColaborador){
        return (
          this.ModoVisualizacao ||
          this.tipo.valor <= 0 ||
          this.tipo.qtdDisponivel <= 0
        );
        
      }else{
        return (
          this.ModoVisualizacao ||
          this.tipo.valor <= 0 ||
          this.tipo.qtdDisponivel <= 0 ||
          this.qtdColaboradores <= 0
        );
      }
    },

    getIdComponentTooltip() {
      return "tooltip_" + this.$helpers.geraNumeroAleatorio();
    },

    getMensagemInputDesabilitado() {
      let mensagem = "Sem colaboradores";

      if (this.ModoVisualizacao) {
        mensagem = "Apenas visualização";
      } else if (this.tipo.id_categoria == null) {
        mensagem = "Não se aplica";
      } else if (this.tipo.valor <= 0) {
        mensagem = "Valor não informado";
      } else if (this.tipo.qtdDisponivel <= 0) {
        mensagem = "Sem estoque";
      }

      return mensagem;
    },
  },
};
</script>

<style scoped>
span {
  display: flex;
  flex-direction: column;
}

input {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border-radius: 6px;
}

.legend {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #c3bbb9;
  text-align: right;
  margin-top: 5px;
  border: none;
  width: 90%;
  background: white;
}
</style>