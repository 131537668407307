<template>
  <div>
    <div v-if="sesiAdmins">
      <sesi-purchase />
    </div>

    <div v-if="companyAdmin">
      <company-purchase />
    </div>
  </div>
</template>

<script>
import SesiPurchase from "@/views/custom-pages/adesao/Purchase/SesiPurchase/SesiPurchase";
import CompanyPurchase from "@/views/custom-pages/adesao/Purchase/CompanyPurchase/CompanyPurchase";
import {actions, subjects} from "@/libs/acl/rules";

export default {
  components: {
    SesiPurchase,
    CompanyPurchase
  },

  created() {
    if(!this.sesiAdmins && !this.companyAdmin) {
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },

  computed: {
    sesiAdmins() {
      return this.$can(actions.VISUALIZAR, subjects.ADESAO_SESI_DR) ||
             this.$can(actions.VISUALIZAR, subjects.ADESAO_SESI_UO);
    },

    companyAdmin() {
      return this.$can(actions.VISUALIZAR, subjects.ADESAO_EMPRESA);
    }
  }
}
</script>
