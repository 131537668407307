<template>
  <div v-if="show">
    <b-modal
      id="abrir-modal-atualizar-observacao"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
      v-model="show"
    >
      <div
        class="
          d-flex
          flex-column
          align-items-center
          justify-center-center
          my-3
          mx-2
        "
      >
        <h1>{{ empresaSelecionada.nome_empresa }}</h1>
        <h2>{{ empresaSelecionada.documento | VMask(getDocumentoMascara) }}</h2>

        <p>Observações e/ou sugestões de datas para o ato vacinal:</p>
        <b-form-textarea
          id="observacao"
          rows="3"
          class="my-2"
          v-model="observacao"
          ref="observacao"
          :disabled="desabilitarSalvar"
        />

        <div role="group">
          <button @click="fecharModal" class="mr-1 btn custom-outline-blue">
            <span v-if="desabilitarSalvar">Fechar</span>
            <span v-else>Desfazer</span>
          </button>

          <button v-if="!desabilitarSalvar"
            @click="atualizarObservacaoDaEmpresa"
            class="ml-1 btn custom-blue"
            :disabled="desabilitarSalvar"
          >
            &nbsp; Salvar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormTextarea } from "bootstrap-vue";

import {
  AdesaoUpdateObservacaoEmpresa,
  AdesaoGetObservacoesDasEmpresas,
  AdesaoHandleVisibilidadeObservacaoModal,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
export default {
  components: {
    BModal,
    BFormTextarea,
  },
  props: {
    ModoVisualizacao: {
      default: false,
    },
  },
  data() {
    return {
      show: false,
      empresaSelecionada: {},
      observacao: "",
    };
  },
  methods: {
    fecharModal() {
      AdesaoHandleVisibilidadeObservacaoModal(null);
    },

    atualizarObservacaoDaEmpresa() {
      AdesaoUpdateObservacaoEmpresa(this.empresaSelecionada, this.observacao);
    },
  },
  computed: {
    getDocumentoMascara() {
      return this.empresaSelecionada.documento.length <= 11
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    desabilitarSalvar() {
      return this.ModoVisualizacao;
    }
  },
  watch: {
    "$store.state.adesaoState.observacoesDasEmpresas.showModal": {
      immediate: true,
      handler() {
        let observacoesDasEmpresas = AdesaoGetObservacoesDasEmpresas();
        this.show = observacoesDasEmpresas.showModal;
        this.empresaSelecionada = observacoesDasEmpresas.empresaSelecionada;

        if (this.empresaSelecionada != null) {
          this.observacao =
            observacoesDasEmpresas.observacoes[
              this.empresaSelecionada.id_empresa
            ];
        }
      },
    },
  },
};
</script>

<style>
</style>
