<template>
  <div class="infos-unit">
    <div
      v-for="vacina in getVacinasComputed.vacinas"
      class="groups-info mb-2"
      :key="vacina.updatedAt"
    >
      <div v-if="handlePossuiPrecificacao(vacina)">
        <h3>{{ vacina.nome_tecnico }}</h3>

        <div
          v-for="estimativa in vacina.estimativas"
          :key="estimativa.updatedAt"
        >
          <p>{{ estimativa.titulo }}</p>
          <h1 :class="handleEstimativa(estimativa)">
            {{ estimativa.saldo_restante }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AdesaoGetResumoVacinas,
  AdesaoGetChooseCompany,
  AdesaoGetVacinasDaEmpresa,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
export default {
  props: {
    IsCompany: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    handleEstimativa(estimativa) {
      return estimativa.saldo_restante < 0 ? "text-danger" : "";
    },
    handlePossuiPrecificacao(vacina) {
      if (this.IsCompany) {
        return this.getVacinasDaEmpresaComputed.includes(vacina.id_vacina);
      }
      return true;
    },
  },
  computed: {
    getVacinasComputed() {
      return AdesaoGetResumoVacinas();
    },
    getVacinasDaEmpresaComputed() {
      return AdesaoGetVacinasDaEmpresa(AdesaoGetChooseCompany()).map(
        (vacina) => {
          return vacina.id_vacina;
        }
      );
    },
  },
};
</script>

<style>
</style>