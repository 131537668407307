<template>
  <b-modal
    id="empresa-select"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    centered
    v-model="modal.show"
    @hidden="fecharModal"
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center mt-1 mb-3 mx-2"
    >
      <h1>Escolha uma empresa</h1>

      <div v-if="loading">Aguarde, buscando dados...</div>

      <v-select
        id="company"
        v-model="modal.empresaSelecionada"
        :options="modal.empresas"
        variant="custom"
        item-text="nome_empresa"
        item-value="id_empresa"
        placeholder="Selecione uma empresa"
        label="nome_empresa"
        class="w-100 my-2"
        v-if="!loading"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>

      <div role="group" v-if="!loading">
        <button @click="fecharModal" class="mr-1 btn custom-blue">
          Voltar
        </button>

        <button
          @click="adicionarEmpresa"
          class="ml-1 btn custom-blue"
          :disabled="modal.empresaSelecionada == null"
        >
          &nbsp; Selecionar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { BModal } from "bootstrap-vue";
import {
  AdesaoGetApiEmpresasParaAdesao,
  AdesaoAdicionarEmpresaSesi,
  AdesaoGetEmpresas,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";

export default {
  components: {
    BModal,
    vSelect,
  },
  data() {
    return {
      modal: {
        show: true,
        empresaSelecionada: null,
        empresas: [],
      },
      loading: true,
    };
  },
  beforeMount() {
    this.handleAdicionarEmpresa();
  },
  methods: {
    handleAdicionarEmpresa() {
      AdesaoGetApiEmpresasParaAdesao().then((empresas) => {
        let idEmpresaJaExistenteNaSessao = AdesaoGetEmpresas().map(
          (empresa) => empresa.id_empresa
        );

        this.modal.empresas = empresas.filter((empresa) => {
          return !idEmpresaJaExistenteNaSessao.includes(empresa.id_empresa);
        });
        this.loading = false;
      });
    },

    fecharModal() {
      this.modal = {
        show: false,
        empresaSelecionada: null,
        empresas: [],
      };
      this.$emit("fechar-modal");
    },
    adicionarEmpresa() {
      AdesaoAdicionarEmpresaSesi(this.modal.empresaSelecionada);
      this.fecharModal();
    },
  },
};
</script>

<style>
#empresa-select .modal-header {
  background: none !important;
}
</style>