<template>
  <button
    type="button"
    :style="getBtnStyles"
    :class="className"
    @click="action"
  >
    <feather-icon
      v-if="featherIcon"
      :size="size"
      :icon="featherIcon"
    />
    <img
      v-else
      alt="icon"
      :src="require(`./icons/${iconImage}`)"
    >
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#2772C0',
    },
    size: {
      type: String,
      default: '18',
    },
    className: {
      type: String,
      default: '',
    },
    featherIcon: {
      type: String,
      default: '',
    },
    iconImage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      btnClass: 'btn btn-custom-clear',
    }
  },

  computed: {
    getBtnStyles() {
      return {
        color: this.color,
        backgroundColor: 'transparent',
        padding: 0,
        border: 'none',
      }
    },
  },

  methods: {
    action() {
      this.$emit('action')
    },
  },
}
</script>
