<template>
  <div :id="`company_${empresa.updatedAt}`">
    <section class="p-2 card" v-if="loading">
      <b-row>
        <b-col cols="12" class="text-center">
          <b-spinner variant="custom" class="mb-1"></b-spinner>
        </b-col>
      </b-row>
    </section>

    <div class="company-card" :class="TipoSesi ? 'sesi' : 'empresa'" v-else>
      <section id="tooltip-drag" class="actions" v-if="TipoSesi">
        <button-icon
          :disabled="getDesabilitarFuncionalidade"
          color="#2772C0"
          size="14"
          v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
          title="Mova a linha para reordenar"
          feather-icon="ChevronUpIcon"
        ></button-icon>
        <button-icon
          :disabled="getDesabilitarFuncionalidade"
          color="#2772C0"
          size="14"
          v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
          title="Mova a linha para reordenar"
          feather-icon="ChevronDownIcon"
        ></button-icon>
      </section>

      <section class="companies-info">
        <div class="company-header">
          <h3>
            {{ empresa.nome_empresa }} -
            {{ empresa.documento | VMask(getDocumentoMascara) }}
          </h3>
          <p
          v-if="!semValidacaoColaborador"
          class="col-5 d-flex ml-0 pl-0"
          v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
          title="Número doses disponíveis para adesão de acordo com cadastros feitos no sistema"
          >
            Trabalhadores: {{ empresa.colaboradores.trabalhador }} |
            Dependentes: {{ empresa.colaboradores.dependente }} | Terceiros:
            {{ empresa.colaboradores.terceiro }}
          </p>

          <h4>
            <span @click="abrirModalObservacaoDaEmpresa(empresa)">
              <feather-icon size="18" icon="AlertCircleIcon" />
              Adicionar Observação/Sugestão data vacinação
            </span>
          </h4>
        </div>

        <div class="company-main">
          <div
            v-for="vacina in getEmpresaVacinas"
            :key="vacina.updatedAt"
            :id="`vacina_${vacina.updatedAt}`"
            :class="vacina.descricao"
          >
            <div class="d-flex">
              <div :style="titleWidth" class="main-title">
                <h4>{{ vacina.nome_tecnico }}</h4>
              </div>

              <section class="com-fomento" v-if="TipoSesi">
                <div :style="distributionWidth" class="main-center">
                  <distribution-input
                    :ModoCriacao="ModoCriacao"
                    :ModoVisualizacao="ModoVisualizacao"
                    :tipo="vacina.doses.com_fomento.trabalhador"
                    :qtdColaboradores="empresa.colaboradores.trabalhador"
                    :semValidacaoColaborador="semValidacaoColaborador"
                    tipagem="com_fomento.trabalhador"
                    :vacina="vacina"
                    :empresa="empresa"
                    background-color="#FCF4E1"
                    border-color="#E7AF1E"
                    text-color="#5E5873"
                    @input="
                      setDoseVacina(
                        vacina,
                        'com_fomento',
                        'trabalhador',
                        $event
                      )
                    "
                  />

                  <distribution-input
                    :ModoCriacao="ModoCriacao"
                    :ModoVisualizacao="ModoVisualizacao"
                    :tipo="vacina.doses.com_fomento.dependente"
                    :qtdColaboradores="empresa.colaboradores.dependente"
                    :semValidacaoColaborador="semValidacaoColaborador"
                    tipagem="com_fomento.dependente"
                    :vacina="vacina"
                    :empresa="empresa"
                    background-color="#FCF4E1"
                    border-color="#E7AF1E"
                    text-color="#5E5873"
                    @input="
                      setDoseVacina(vacina, 'com_fomento', 'dependente', $event)
                    "
                  />

                  <distribution-input
                    :ModoCriacao="ModoCriacao"
                    :ModoVisualizacao="ModoVisualizacao"
                    :tipo="vacina.doses.com_fomento.terceiro"
                    :qtdColaboradores="empresa.colaboradores.terceiro"
                    :semValidacaoColaborador="semValidacaoColaborador"
                    tipagem="com_fomento.terceiro"
                    :vacina="vacina"
                    :empresa="empresa"
                    background-color="#FCF4E1"
                    border-color="#E7AF1E"
                    text-color="#5E5873"
                    @input="
                      setDoseVacina(vacina, 'com_fomento', 'terceiro', $event)
                    "
                  />
                </div>
              </section>

              <section class="sem-fomento">
                <div :style="distributionWidth" class="main-center">
                  <distribution-input
                    :ModoCriacao="ModoCriacao"
                    :ModoVisualizacao="ModoVisualizacao"
                    :tipo="vacina.doses.sem_fomento.trabalhador"
                    :qtdColaboradores="empresa.colaboradores.trabalhador"
                    :semValidacaoColaborador="semValidacaoColaborador"
                    tipagem="sem_fomento.trabalhador"
                    :vacina="vacina"
                    :empresa="empresa"
                    background-color="#F3EEFF"
                    border-color="#8C65E2"
                    text-color="#5E5873"
                    @input="
                      setDoseVacina(
                        vacina,
                        'sem_fomento',
                        'trabalhador',
                        $event
                      )
                    "
                  />

                  <distribution-input
                    :ModoCriacao="ModoCriacao"
                    :ModoVisualizacao="ModoVisualizacao"
                    :tipo="vacina.doses.sem_fomento.dependente"
                    :qtdColaboradores="empresa.colaboradores.dependente"
                    :semValidacaoColaborador="semValidacaoColaborador"
                    tipagem="sem_fomento.dependente"
                    :vacina="vacina"
                    :empresa="empresa"
                    background-color="#F3EEFF"
                    border-color="#8C65E2"
                    text-color="#5E5873"
                    @input="
                      setDoseVacina(vacina, 'sem_fomento', 'dependente', $event)
                    "
                  />

                  <distribution-input
                    :ModoCriacao="ModoCriacao"
                    :ModoVisualizacao="ModoVisualizacao"
                    :tipo="vacina.doses.sem_fomento.terceiro"
                    :qtdColaboradores="empresa.colaboradores.terceiro"
                    :semValidacaoColaborador="semValidacaoColaborador"
                    tipagem="sem_fomento.terceiro"
                    :vacina="vacina"
                    :empresa="empresa"
                    background-color="#F3EEFF"
                    border-color="#8C65E2"
                    text-color="#5E5873"
                    @input="
                      setDoseVacina(vacina, 'sem_fomento', 'terceiro', $event)
                    "
                  />
                </div>
              </section>

              <div :style="totalsWidth" class="main-actions">
                <div class="total-items qty">
                  <span>Qt. Doses</span>
                  <h4
                    v-text="
                      empresa.carrinho.vacinas[vacina.id_vacina].qtdTotalDoses
                    "
                  ></h4>
                </div>

                <div class="total-items price">
                  <span>Valor R$</span>
                  <h4
                    v-text="
                      formatTo2Decimal(
                        empresa.carrinho.vacinas[vacina.id_vacina].qtdTotalValor
                      )
                    "
                  ></h4>
                </div>

                <div class="total-items h-100 pb-1" v-if="TipoSesi">
                  <button-icon
                    iconImage="EraserIcons.png"
                    @action="limparCamposDosesVacinas(empresa, vacina)"
                    :disabled="getDesabilitarFuncionalidade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="line-sum">
          <span></span>
        </div>

        <div :style="totalsWidth" class="total-sum">
          <div class="total-items qty">
            <span>Qt. Doses</span>
            <h4>{{ empresa.carrinho.doses }}</h4>
          </div>

          <div class="total-items price">
            <span>Valor R$</span>
            <h4 v-text="formatTo2Decimal(empresa.carrinho.valor)"></h4>
          </div>
        </div>
      </section>

      <section
        class="actions"
        v-if="TipoSesi"
        :class="ModoCriacao ? 'criacao' : 'bg-white'"
      >
        <button-icon
          @action="removerEmpresa(empresa)"
          color="#2772C0"
          size="18"
          feather-icon="Trash2Icon"
          :disabled="getDesabilitarFuncionalidade"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { BButton, BLink, BSpinner, BRow, BCol, BTooltip } from "bootstrap-vue";
import { columns } from "@/views/custom-pages/adesao/config";
import DistributionInput from "@/views/components/custom/Inputs/DistributionInput/DistributionInput.vue";
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
import {
  AdesaoGetVacinasDaEmpresa,
  AdesaoLimparCamposDosesVacinasDaEmpresa,
  AdesaoRemoverEmpresa,
  AdesaoSetDoseVacina,
  AdesaoHandleVisibilidadeObservacaoModal,
  AdesaoGetVacinas,
  AdesaoGetChooseCampaign
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";

export default {
  components: {
    BButton,
    BLink,
    BSpinner,
    BRow,
    BCol,
    DistributionInput,
    ButtonIcon,
    BTooltip,
  },

  props: {
    EmpresaProp: {
      required: true,
    },
    TipoSesi: {
      required: true,
    },
    ModoCriacao: {
      default: true,
    },
    ModoVisualizacao: {
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      empresa: {},
      valueInput: "OK",
      titleWidth: {
        width: `${columns.bottomColumnLeft.width - 28}px`,
      },

      distributionWidth: {
        width: `${columns.topColumn1.width}px`,
      },

      totalsWidth: {
        width: `${columns.bottomColumnRight.width - 28}px`,
      },
      tipoColaboradores: ["trabalhador", "dependente", "terceiro"],
    };
  },

  beforeMount() {
    this.empresa = this.EmpresaProp;
  },

  created() {
    this.loading = false;
  },

  methods: {
    removerEmpresa(empresa) {
      AdesaoRemoverEmpresa(empresa);
    },
    limparCamposDosesVacinas(empresa, vacina) {
      AdesaoLimparCamposDosesVacinasDaEmpresa(empresa, vacina);
    },

    setDoseVacina(vacina, campo, tipo, event) {
      AdesaoSetDoseVacina(this.empresa, vacina, campo, tipo, Number(event));
    },

    getTipoDeIncentivo(estimativa) {
      return this.$helpers.ehDoTipoComFomento(estimativa.id_incentivo)
        ? "com_fomento"
        : "sem_fomento";
    },

    getEhTipoDaEstimativa(estimativa, categoria) {
      return estimativa.id_categoria === categoria.id_categoria;
    },

    abrirModalObservacaoDaEmpresa(empresa) {
      AdesaoHandleVisibilidadeObservacaoModal(empresa);
    },

    formatTo2Decimal(number) {
      if (typeof number == "string") {
        number = parseFloat(number);
      }
      return number.toLocaleString("pt-BR", { minimumFractionDigits: "2" });
    },
  },
  computed: {
    getVacinas() {
      return AdesaoGetVacinas();
    },
    getEmpresaVacinas() {
      return AdesaoGetVacinasDaEmpresa(this.EmpresaProp);
    },
    getDocumentoMascara() {
      return this.empresa.documento.length <= 11
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    getDesabilitarFuncionalidade() {
      return this.ModoVisualizacao;
    },
    semValidacaoColaborador() {
      return AdesaoGetChooseCampaign().permite_adesao_parcial
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./company.scss";
.company-card.empresa {
  .total-sum {
    width: 250px !important;
  }
  .companies-info {
    width: 100%;
    .main-title {
      width: 250px !important;
      padding: 0;
    }
    .sem-fomento {
      width: 450px;
      padding: 0 !important;

      .main-center {
        margin: 0;
        width: inherit !important;
      }
    }
    .main-actions {
      width: 250px !important;
      padding: 0;
      justify-content: center;
    }
  }
}
::v-deep .company-card.empresa {
  .total-items.price,
  input.legend {
    display: none;
  }

  input[type="number"] {
    width: 120px !important;
    margin: 0 auto;
  }
}
</style>