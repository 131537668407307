import axiosIns from "@/libs/axios";
import rotasAPI from "@/router/rotasAPI";

export const getCampaignsByStatus = (params) => {
    return new Promise((resolve, reject) => {
        axiosIns
            .get(rotasAPI.campanha(), { params })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const columns = {
    topColumn1: {
        width: 225,
        backgroundColor: '#E7AF1E',
        text: 'COM FOMENTO'
    },

    topColumn2: {
        width: 225,
        backgroundColor: '#8C65E2',
        text: 'SEM FOMENTO'
    },

    bottomColumnLeft: {
        width: 250,
        backgroundColor: '#2772C0',
        text: 'ESTABELECIMENTO | VACINA',
    },

    bottomColumnRight: {
        width: 250,
        backgroundColor: '#2772C0',
        text: 'TOTAL'
    },

    bottomColumnCenter1: {
        width: 75,
        backgroundColor: '#F6CB5C',
        text: 'TRAB.'
    },

    bottomColumnCenter2: {
        width: 75,
        backgroundColor: '#F6CB5C',
        text: 'TER.'
    },

    bottomColumnCenter3: {
        width: 75,
        backgroundColor: '#F6CB5C',
        text: 'DEP.'
    },

    bottomColumnCenter4: {
        width: 75,
        totalWidth: 150,
        backgroundColor: '#BCA8EA',
        text: 'TRAB.',
        totalText: 'TRABALHADOR'
    },

    bottomColumnCenter5: {
        width: 75,
        totalWidth: 150,
        backgroundColor: '#BCA8EA',
        text: 'TER.',
        totalText: 'TERCEIRO'
    },

    bottomColumnCenter6: {
        width: 75,
        totalWidth: 150,
        backgroundColor: '#BCA8EA',
        text: 'DEP.',
        totalText: 'DEPENDENTE'
    },
}