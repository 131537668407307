<template>
  <div :style="getStyleProps" class="cell">
    <p>{{ getText }}</p>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 198
    },
    height: {
      type: Number,
      default: 35
    },
    backgroundColor: {
      type: String,
      default: '#2772C0'
    },
    textColor: {
      type: String,
      default: '#fff'
    },
    text: {
      type: String,
      default: 'Title Cell'
    }
  },

  computed: {
    getStyleProps() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        backgroundColor: this.backgroundColor,
        color: this.textColor,
      }
    },

    getText() {
      return this.text
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./cell.scss";
</style>
