<template>
  <header class="d-flex flex-column align-items-center justify-content-center">
    <div v-if="sesiHeader" class="d-flex">
      <cell
          :width="columns.topColumn1.width"
          :background-color="columns.topColumn1.backgroundColor"
          :text="columns.topColumn1.text"
      />
      <cell
          :width="columns.topColumn2.width"
          :background-color="columns.topColumn2.backgroundColor"
          :text="columns.topColumn2.text"
      />
    </div>

    <div class="d-flex">
      <cell
          :width="columns.bottomColumnLeft.width"
          :background-color="columns.bottomColumnLeft.backgroundColor"
          :text="columns.bottomColumnLeft.text"
      />

      <div v-if="sesiHeader" class="d-flex">
        <cell
            :width="columns.bottomColumnCenter1.width"
            :background-color="columns.bottomColumnCenter1.backgroundColor"
            :text="columns.bottomColumnCenter1.text"
        />
        <cell
            :width="columns.bottomColumnCenter3.width"
            :background-color="columns.bottomColumnCenter3.backgroundColor"
            :text="columns.bottomColumnCenter3.text"
        />
        <cell
            :width="columns.bottomColumnCenter2.width"
            :background-color="columns.bottomColumnCenter2.backgroundColor"
            :text="columns.bottomColumnCenter2.text"
        />
        
      </div>
      <cell
          :width="getWidthCenter"
          :background-color="columns.bottomColumnCenter4.backgroundColor"
          :text="getTextCenter4"
      />
      <cell
          :width="getWidthCenter"
          :background-color="columns.bottomColumnCenter6.backgroundColor"
          :text="getTextCenter6"
      />
      <cell
          :width="getWidthCenter"
          :background-color="columns.bottomColumnCenter5.backgroundColor"
          :text="getTextCenter5"
      />
      

      <cell
          :width="columns.bottomColumnRight.width"
          :background-color="columns.bottomColumnRight.backgroundColor"
          :text="columns.bottomColumnRight.text"
      />
    </div>
  </header>
</template>

<script>
import Cell from '@/views/custom-pages/adesao/components/Cell/Cell.vue'
import { columns } from '@/views/custom-pages/adesao/config'

export default {
  components: {
    Cell,
  },

  props: {
    sesiHeader: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      columns,
    }
  },

  computed: {
    getWidthCenter() {
      return this.sesiHeader
        ? columns.bottomColumnCenter4.width
        : columns.bottomColumnCenter4.totalWidth
    },

    getTextCenter4() {
      return this.sesiHeader
          ? columns.bottomColumnCenter4.text
          : columns.bottomColumnCenter4.totalText
    },

    getTextCenter5() {
      return this.sesiHeader
          ? columns.bottomColumnCenter5.text
          : columns.bottomColumnCenter5.totalText
    },

    getTextCenter6() {
      return this.sesiHeader
          ? columns.bottomColumnCenter6.text
          : columns.bottomColumnCenter6.totalText
    }
  }
}
</script>
