<template>
  <section
    :key="getInformacoesPersistencia.updatedAt"
    v-if="getInformacoesPersistencia.status != null"
  >
    <!-- modal Success -->
    <b-modal
      id="abrir-modal-success-show"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
      v-model="modal.successShow"
    >
      <div :class="classesModal">
        <feather-icon
          icon="CheckCircleIcon"
          class="text-success mb-2"
          size="80"
        />

        <h3 class="text-center">
          {{ getInformacoesPersistencia.mensagens.join("") }}
        </h3>

        <div class="d-flex mt-2">
          <button class="btn custom-blue" @click="btnFecharModal(true)">
            Ok
          </button>
        </div>
      </div>
    </b-modal>
    <!-- modal Success -->

    <!-- modal error -->
    <b-modal
      id="abrir-modal-error-show"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
      v-model="modal.errorShow"
    >
      <div :class="classesModal">
        <feather-icon icon="XCircleIcon" class="text-danger mb-2" size="80" />

        <h3 class="text-center">Erro ao Lançar Pedido.</h3>

        <p>Corrija os campos em vermelho para prosseguir.</p>
        <p>
          <span
            v-for="msg in mensagens"
            class="d-block"
            :key="msg._id"
            v-html="msg"
          ></span>
        </p>
        <div class="d-flex mt-2">
          <button class="btn custom-blue" @click="btnFecharModal(false)">
            Ok
          </button>
        </div>
      </div>
    </b-modal>
    <!-- modal error -->
  </section>
</template>

<script>
import { BModal } from "bootstrap-vue";
import {
  AdesaoGetInformacoesPersistencia,
  AdesaoSetInformacoesPersistencia,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
export default {
  components: {
    BModal,
  },
  data() {
    return {
      classesModal:
        "d-flex flex-column align-items-center justify-center-center my-2",
      modal: {
        successShow: false,
        errorShow: false,
      },
      mensagens: [],
    };
  },
  created() {
    this.setMensagens();
  },
  methods: {
    setMensagens() {
      this.mensagens = [];
      AdesaoGetInformacoesPersistencia().mensagens.forEach((item) => {
        const replacedText = item
          .replace(/\\n/g, "<br>")
          .replace(/<br>/g, "\n")
          .split("\n")
          .filter(Boolean);

        this.mensagens = this.mensagens.concat(replacedText);
      });
    },
    btnFecharModal(redirect = false) {
      AdesaoSetInformacoesPersistencia(null, []);
      this.fecharModal(redirect);
    },
    fecharModal(redirect = false) {
      this.modal = {
        successShow: false,
        errorShow: false,
      };

      if (redirect) {
        this.$router.push({ name: "acceptances" });
      }
    },
  },
  computed: {
    getInformacoesPersistencia() {
      this.fecharModal();
      this.setMensagens();
      let persistencia = AdesaoGetInformacoesPersistencia(),
        successShow = false,
        errorShow = false;

      if (persistencia.status == "success") {
        successShow = true;
      } else {
        errorShow = true;
      }

      this.modal = { successShow, errorShow };

      return persistencia;
    },
    ehEmpresaAdesao() {
      return (
        AdesaoGetInformacoesPersistencia().mensagens.join() ==
        "Sua adesão foi enviada."
      );
    },
  },
};
</script>

<style>
</style>